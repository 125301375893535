export const LANGUAGES = {
    "zh_cn": {
        name: "中文（简体）",
        branches: {
            vanilla: "原版",
            experiment: "实验性玩法",
            education: "教育版"
        }
    },
    // "en": {
    //     name: "English"
    // }
}

export default {
    LANGUAGES
}
